enum routes {
  CONTATO = '/contato',
  DADOS_RECEBIDOS = '/dados-recebidos',
  DADOS_PROFISSIONAIS = '/dados-profissionais',
  CONVENIO_FORCAS_ARMADAS = '/convenio-forcas-armadas',
  INSS_SIMPLIFICADO = '/inss-simplificado',
  AUTORIZACAO_CONSULTA_INSS = '/inss-simplificado/autorizacao-consulta',
  SITUACAO_FUNCIONAL = '/situacao-funcional',
  DADOS_PESSOAIS = '/dados-pessoais',
  SELECAO_GRUPO = '/selecao-grupo',
  TOKEN = '/token',
  NAO_LIBERADO = '/nao-liberado',
  ONBOARDING = '/onboarding',
  RESULTADO = '/resultado',
  ROOT = '/',
}
export default routes
