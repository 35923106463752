import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { Page } from '@templates'
import { Button } from '@interco/inter-ui/components/Button'
import { ApplicationState } from '@store/types'
import { Paragraph } from '@atoms'
import { Colors } from '@utils/colors'
import { ConveniosCodes, FontesPagamento, StepPortabilidade } from '@utils/enums'
import { useTrackingPortabilidade } from '@hooks'
import routes from '@routes/routes'
import { trackingRequest } from '@store/portabilidade/simulacao/actions'
import { findFontePagamento, findFontePagamentoForcasArmadas } from '@utils/functions'
import ConvenioDropdown from '@organisms/InputsDropdown/ConvenioDropdown'
import {
  transformCidadeToOption,
  transformConvenioToOption,
  transformEstadoToOption,
  transformOrgaoToOption,
} from '@utils/transformers'
import {
  setCidadeSelecionada,
  setConvenioSelecionado,
  setEstadoSelecionado,
  setOrgaoSelecionado,
} from '@store/portabilidade/dadosProfissionais/actions'
import { Dialog } from '@molecules'
import { DadosProfissionaisComponentsTypes } from '@store/portabilidade/dadosProfissionais/types'
import EstadoDropdown from '@organisms/InputsDropdown/EstadoDropdown'
import CidadeDropdown from '@organisms/InputsDropdown/CidadeDropdown'
import { cidadesRequest, estadosRequest, orgaosRequest } from '@store/globalApi/actions'
import OrgaoDropdown from '@organisms/InputsDropdown/OrgaoDropdown'
import { VariantDialog } from '@utils/bottomSheetVariants'

const DadosProfissionais = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {
    fonteSelecionada,
    convenioSelecionado,
    cidadeSelecionada,
    estadoSelecionado,
    orgaoSelecionado,
  } = useSelector((state: ApplicationState) => state.portabilidade.dadosProfissionais)

  const {
    conveniosLoading,
    estados,
    cidades,
    convenios = [],
    orgaos = [],
    orgaosLoading,
  } = useSelector((state: ApplicationState) => state.globalApi)

  const [open, setOpen] = useState(false)
  const [variant, setVariant] = useState<VariantDialog>()
  const [disabled, setDisabled] = useState(false)

  const fontesConvenioDropdown =
    fonteSelecionada === FontesPagamento.MUNICIPAL ||
    fonteSelecionada === FontesPagamento.ESTADUAL ||
    fonteSelecionada === FontesPagamento.TRIBUNAIS

  const fontesEstadoCidadeDropdown =
    fonteSelecionada === FontesPagamento.ESTADUAL ||
    fonteSelecionada === FontesPagamento.FEDERAL_SIAPE ||
    fonteSelecionada === FontesPagamento.FORCAS_ARMADAS ||
    fonteSelecionada === FontesPagamento.INSS

  const fontesOrgaoDropdown = fonteSelecionada === FontesPagamento.ESTADUAL

  useTrackingPortabilidade(StepPortabilidade.DADOS_PROFISSIONAIS)

  const selectTitle = () => {
    if (fonteSelecionada === FontesPagamento.FORCAS_ARMADAS) {
      return findFontePagamentoForcasArmadas(Number(convenioSelecionado) || 0)
    }
    return findFontePagamento(fonteSelecionada || 0)
  }

  useEffect(() => {
    setDisabled(
      (fontesEstadoCidadeDropdown && !cidadeSelecionada) ||
        (fontesEstadoCidadeDropdown && !estadoSelecionado) ||
        (fontesConvenioDropdown && !convenioSelecionado) ||
        (fontesOrgaoDropdown && !orgaoSelecionado),
    )
  }, [
    cidadeSelecionada,
    convenioSelecionado,
    estadoSelecionado,
    fontesConvenioDropdown,
    fontesEstadoCidadeDropdown,
    fontesOrgaoDropdown,
    orgaoSelecionado,
  ])

  useEffect(() => {
    if (fonteSelecionada === FontesPagamento.FEDERAL_SIAPE) {
      dispatch(setConvenioSelecionado(ConveniosCodes.SIAPE_SERVIDOR_FEDERAL))
    }
  }, [dispatch, fonteSelecionada])

  useEffect(() => {
    convenioSelecionado !== '' && dispatch(orgaosRequest(Number(convenioSelecionado)))
  }, [convenioSelecionado, dispatch])

  const showDropdown = (component: DadosProfissionaisComponentsTypes) => {
    if (component === 'convenio' && fontesConvenioDropdown) {
      return true
    }
    if (component === 'estado' && fontesEstadoCidadeDropdown) {
      return true
    }
    if (component === 'orgao' && fontesOrgaoDropdown) {
      return true
    }
    if (component === 'cidade' && fontesEstadoCidadeDropdown) {
      return true
    }
    return false
  }

  return (
    <Page
      id="page-dados-profissionais"
      stickyFooter={
        <Button
          disabled={disabled}
          onClick={() => {
            dispatch(trackingRequest(StepPortabilidade.CONTINUAR_DADOS_PROFISSIONAIS))
            if (orgaos.length !== 0) dispatch(setOrgaoSelecionado(orgaos[0].codigo))
            navigate(routes.SITUACAO_FUNCIONAL)
          }}
        >
          Continuar
        </Button>
      }
    >
      <Paragraph
        variant="sora"
        fontWeight={600}
        fontSize="24px"
        lineHeight="30px"
        color={Colors.GRAY500}
        margin="0 0 24px"
      >
        {selectTitle()}
      </Paragraph>
      <>
        {showDropdown('convenio') && (
          <ConvenioDropdown
            id="dropdown-convenio"
            convenioSelecionado={transformConvenioToOption(convenios, Number(convenioSelecionado))}
            loading={conveniosLoading}
            convenios={convenios}
            onChange={(option) => {
              dispatch(setConvenioSelecionado(option.value))
              dispatch(estadosRequest())
            }}
            setOpen={() => setOpen(true)}
            setVariant={setVariant}
          />
        )}
      </>
      <>
        {showDropdown('orgao') && (
          <OrgaoDropdown
            id="dropdown-orgao"
            orgaos={orgaos}
            orgaosLoading={orgaosLoading}
            disabled={fontesOrgaoDropdown && !convenioSelecionado}
            orgaoSelecionado={transformOrgaoToOption(orgaos, Number(orgaoSelecionado))}
            convenioSelecionado={Number(convenioSelecionado)}
            onChange={(option) => dispatch(setOrgaoSelecionado(option.value))}
          />
        )}
      </>
      <>
        {showDropdown('estado') && (
          <EstadoDropdown
            id="dropdown-estado"
            disabled={fontesOrgaoDropdown && !convenioSelecionado}
            estadoSelecionado={transformEstadoToOption(estados, String(estadoSelecionado))}
            onChange={(option) => {
              dispatch(setEstadoSelecionado(option.value))
              if (cidadeSelecionada) {
                dispatch(setCidadeSelecionada(undefined))
              }
              dispatch(cidadesRequest(String(option.value)))
            }}
          />
        )}
      </>
      <>
        {showDropdown('cidade') && (
          <CidadeDropdown
            id="dropdown-cidade"
            cidadeSelecionada={transformCidadeToOption(cidades, String(cidadeSelecionada))}
            onChange={(option) => dispatch(setCidadeSelecionada(option.value))}
            isDisabled={!estadoSelecionado}
          />
        )}
      </>

      {open ? (
        <Dialog
          onClose={() => setOpen(false)}
          headerTitle={variant?.title || ''}
          description={variant?.text}
          buttonText="Entendi"
        />
      ) : (
        <></>
      )}
    </Page>
  )
}

export default DadosProfissionais
