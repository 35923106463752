import { Reducer } from 'redux'

import { ContratoAtualState, ContratoAtualTypes } from './types'

const INITIAL_STATE: ContratoAtualState = {
  bancoSelecionado: '',
  valorParcela: '',
  loading: false,
  error: false,
}

const reducer: Reducer<ContratoAtualState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ContratoAtualTypes.SET_BANCO_SELECIONADO:
      return { ...state, bancoSelecionado: action.payload }
    case ContratoAtualTypes.SET_NUMERO_CONTRATO:
      return { ...state, valorParcela: action.payload }
    case ContratoAtualTypes.RESET:
      return { ...INITIAL_STATE }
    default:
      return state
  }
}

export default reducer
