import { ConvenioApi } from '@store/globalApi/types'
import { BaseServices, HttpMethod } from '@services/base'

export default (fontePagamento: number) => {
  const pathname = `${process.env.REACT_APP_CONVENIOS}?familia=${fontePagamento}`
  const method = HttpMethod.GET
  const host = process.env.REACT_APP_API_HOST

  const response = BaseServices.request<ConvenioApi>({
    pathname,
    method,
    host,
  })
  return response
}
