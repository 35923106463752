/**
 * Páginas mapeadas para o new relic
 */
export enum StepPortabilidade {
  CONTRATO_ATUAL = 'contrato-atual',
  CONTINUAR_CONTRATO_ATUAL = 'continuar-contrato-atual',
  FONTE_PAGAMENTO_GRUPO = 'fonte-pagamento-grupo',
  CONTINUAR_FONTE_PAGAMENTO_GRUPO = 'continuar-fonte-pagamento-grupo',
  DADOS_PESSOAIS = 'dados-pessoais',
  CONTINUAR_DADOS_PESSOAIS = 'continuar-dados-pessoais',
  DADOS_PROFISSIONAIS = 'dados-profissionais',
  CONTINUAR_DADOS_PROFISSIONAIS = 'continuar-dados-profissionais',
  DADOS_RECEBIDOS = 'dados-recebidos',
  CONTATO = 'contato',
  CONFIRMAR_CONTATO = 'confirmar-contato',
  NAO_LIBERADO = 'nao-liberado',
  VALIDA = 'valida',
  RESULTADO = 'resultado',
  TOKEN = 'token',
  TOKEN_VALIDADO = 'token-validado',
  ENVIO_TOKEN = 'envio-token',
  CONVENIO_FORCAS_ARMADAS = 'convenio-forcas-armadas',
  CONTINUAR_CONVENIO_FORCAS_ARMADAS = 'continuar-convenio-forcas-armadas',
  SITUACAO_FUNCIONAL = 'situacao-funcional',
  CONTINUAR_SITUACAO_FUNCIONAL = 'continuar-situacao-funcional',
}

/**
 * Erros em catch no store
 */
export enum ErrorCodes {
  P0_001_BANCOS = 'PO.001', // src/store/globalApi/sagas/getBancos.ts
  P0_002_FONTES = 'PO.002', // src/store/globalApi/sagas/getFontes.ts
  P0_003_ESTADOS = 'PO.003', // src/store/globalApi/sagas/getEstados.ts
  P0_004_CIDADES = 'PO.004', // src/store/globalApi/sagas/getCidades.ts
  P0_005_CONVENIOS = 'PO.005', // src/store/globalApi/sagas/getConvenio.ts
  P0_006_ORGAOS = 'PO.006', // src/store/globalApi/sagas/getOrgaos.ts
  P0_007_UPAGS = 'PO.007', // src/store/globalApi/sagas/getUpags.ts
  P0_008_LEAD = 'PO.008', // src/store/portabilidade/simulacao/sagas/postSalvarLead.ts
}

/**
 * Indicativos para enviar no lead
 */
export const TiposIndicativos = {
  ativo: [
    {
      value: 1,
      label: 'Militar da ativa de carreira',
    },
    {
      value: 2,
      label: 'Militar da ativa temporário',
    },
    { value: 3, label: 'Militar reintegrado' },
  ],
  inativo: [
    { value: 1, label: 'Militar na inatividade' },
    { value: 2, label: 'Militar reformado' },
    {
      value: 3,
      label: 'Militar reformado por decisão judicial',
    },
    {
      value: 4,
      label: 'Militar reformado por idade limite',
    },
    {
      value: 8,
      label: 'Militar anistiado político',
    },
  ],
  estabilizado: [
    { value: 1, label: 'Pensão julgada pelo TCU' },
    {
      value: 2,
      label: 'Pensão remetida e não julgada pelo TCU 3 - pensão não remetida ao TCU',
    },
    {
      value: 4,
      label: 'Pensão julgada ilegal pelo TCU',
    },
    { value: 5, label: 'Pensão do STM' },
    {
      value: 6,
      label: 'Pensão militar civil nível intermediário',
    },
    {
      value: 7,
      label: 'Pensão militar civil nível superior',
    },
    { value: 8, label: 'Pensão anistiado político' },
  ],
}

/**
 * Tipos de fonte de pagamento
 */
export enum FontesPagamento {
  DEFAULT = 0,
  ESTADUAL = 1,
  MUNICIPAL = 2,
  INSS = 3,
  FEDERAL_SIAPE = 4,
  FORCAS_ARMADAS = 7,
  TRIBUNAIS = 8,
  CLT = 5,
  AUTONOMO = 9,
}

/**
 * label de fonte de pagamento
 */
export const FontesPagamentoTitle = [
  {
    value: 1,
    label: 'Público Estadual',
  },
  {
    value: 2,
    label: 'Público Municipal',
  },
  {
    value: 3,
    label: 'Aposentado ou pensionista do INSS',
  },
  {
    value: 4,
    label: 'SIAPE',
  },
  {
    value: 7,
    label: 'Forças Armadas',
  },
  {
    value: 8,
    label: 'Tribunais',
  },
]

/**
 * Patentes do exercito
 */
export const PatentesExercito = [
  { value: 'Soldado EV', text: 'Soldado EV' },
  { value: 'Soldado EP', text: 'Soldado EP' },
  { value: 'Cabo', text: 'Cabo' },
  { value: '3º Sargento', text: '3º Sargento' },
  { value: '2º Sargento', text: '2º Sargento' },
  { value: '1º Sargento', text: '1º Sargento' },
  { value: 'Subtenente', text: 'Subtenente' },
  { value: 'Aspirante a Oficial', text: 'Aspirante a Oficial' },
  { value: '2º Tenente', text: '2º Tenente' },
  { value: '1º Tenente', text: '1º Tenente' },
  { value: 'Capitão', text: 'Capitão' },
  { value: 'Major', text: 'Major' },
  { value: 'Tenente-Coronel', text: 'Tenente-Coronel' },
  { value: 'Coronel', text: 'Coronel' },
  { value: 'General de Brigada', text: 'General de Brigada' },
  { value: 'General de Divisão', text: 'General de Divisão' },
  { value: 'General de Exército', text: 'General de Exército' },
]

/**
 * Codigos de cada convênio
 */
export enum ConveniosCodes {
  ESTADO_MG = 1,
  TJMG = 2,
  SIAPE_SERVIDOR_FEDERAL = 3,
  ESTADO_RJ = 4,
  ESTADO_SP = 10,
  ESTADO_BAHIA = 12,
  ESTADO_MS = 14,
  ESTADO_GO = 20,
  INSS = 6,
  MIN_PUB_MG = 43,
  TRE_MG = 45,
  METRO = 46,
  TRE_SP = 55,
  STJ = 56,
  TCE_MG = 62,
  TRT = 75,
  ESTADO_MT = 105,
  MARINHA_BR = 13,
  FORCA_AEREA_BR = 23,
  EXERCITO_BR = 37,
  PREF_BH = 5,
  PREF_SALVADOR = 16,
  PREF_SP = 17,
  PREF_CONTAGEM = 34,
  HOSPITAL_ODILON = 42,
  OUTROS = 999,
}

/**
 * convênio das forças armadas
 */
export const ForcasArmadasConvenios = [
  { value: ConveniosCodes.EXERCITO_BR, label: 'Exército do Brasil' },
  { value: ConveniosCodes.FORCA_AEREA_BR, label: 'Força Aérea do Brasil' },
  { value: ConveniosCodes.MARINHA_BR, label: 'Marinha do Brasil' },
]

/**
 * situacao funcional municipal
 */
export const SituacoesMunicipal = [
  { value: 'Ativos / Efetivos / Concursado', text: 'Ativos / Efetivos / Concursado' },
  { value: 'Aposentados', text: 'Aposentados' },
  { value: 'Pensionistas', text: 'Pensionistas' },
  { value: 'Celetistas', text: 'Celetistas' },
  { value: 'Comissionados', text: 'Comissionados' },
  { value: 'Temporários / Contratados', text: 'Temporários / Contratados' },
  { value: 'Bolsista', text: 'Bolsista' },
  { value: 'Pensionistas Curatelados/Tutelados', text: 'Pensionistas Curatelados/Tutelados' },
  { value: 'Estagiários', text: 'Estagiários' },
]

/**
 * situacao funcional estadual
 */
export const SituacoesEstadual = [
  {
    value: 'Ativos / Efetivos / Concursados / Estatutários',
    text: 'Ativos / Efetivos / Concursados / Estatutários',
  },
  { value: 'Aposentados / Inativos', text: 'Aposentados / Inativos' },
  { value: 'Bolsistas', text: 'Bolsistas' },
  { value: 'Comissionados', text: 'Comissionados' },
  { value: 'CLT / Celetistas', text: 'CLT / Celetistas' },
  { value: 'Pensionistas', text: 'Pensionistas' },
  { value: 'Pensionistas Curatelados/Tutelados', text: 'Pensionistas Curatelados/Tutelados' },
  { value: 'Temporários / Contratados', text: 'Temporários / Contratados' },
  { value: 'Convocados', text: 'Convocados' },
  { value: 'Estagiário / Aprendiz', text: 'Estagiário / Aprendiz' },
]

/**
 * situacao funcional  siape
 */
export const SituacoesFederalSiape = [
  { value: 'Ativos / Efetivos', text: 'Ativos / Efetivos' },
  { value: 'Aposentados / Inativos', text: 'Aposentados / Inativos' },
  { value: 'Pensionistas Vitalícios', text: 'Pensionistas Vitalícios' },
  { value: 'Pensionistas Curatelados/Tutelados', text: 'Pensionistas Curatelados/Tutelados' },
  { value: 'Comissionados', text: 'Comissionados' },
  { value: 'Temporários / Contratados', text: 'Temporários / Contratados' },
]

/**
 * situacao funcional tribunais
 */
export const SituacoesTribunais = [
  { value: 'Ativos / Efetivos', text: 'Ativos / Efetivos' },
  { value: 'Magistrados', text: 'Magistrados' },
  { value: 'Aposentados / Inativos', text: 'Aposentados / Inativos' },
  { value: 'Pensionistas', text: 'Pensionistas' },
  { value: 'Comissionados', text: 'Comissionados' },
  { value: 'Temporários / Contratados', text: 'Temporários / Contratados' },
  { value: 'CLT / Celetistas', text: 'CLT / Celetistas' },
  { value: 'Ministros politicamente expostos', text: 'Ministros politicamente expostos' },
]

/**
 * situacao funcional forças armadas
 */
export const SituacoesForcasArmadas = [
  { value: 'Ativos / Efetivos', text: 'Ativos / Efetivos' },
  { value: 'Inativos', text: 'Inativos' },
  { value: 'Veteranos', text: 'Veteranos' },
  { value: 'Pensionistas', text: 'Pensionistas' },
  { value: 'Pensionistas Curatelados/Tutelados', text: 'Pensionistas Curatelados/Tutelados' },
  { value: 'Comissionados', text: 'Comissionados' },
  { value: 'Temporários / Contratados', text: 'Temporários / Contratados' },
  { value: 'Estagiários', text: 'Estagiários' },
]

/**
 * situacao funcional inss
 */
export const SituacoesInss = [
  { value: 'Aposentados', text: 'Aposentados' },
  { value: 'Pensionistas', text: 'Pensionistas' },
  { value: 'Pensionistas Curatelados/Tutelados', text: 'Pensionistas Curatelados/Tutelados ' },
]

/**
 * motivos de situacao funcional inss
 */
export const MotivosInss = {
  aposentado: [
    { value: 'Idade', text: 'Idade' },
    { value: 'Tempo de contribuição', text: 'Tempo de contribuição' },
    {
      value: 'Encargos Previdenciários da União',
      text: 'Encargos Previdenciários da União',
    },
    { value: 'Invalidez', text: 'Invalidez' },
  ],
  pensionista: [{ value: 'Por morte', text: 'Por morte' }],
}

/**
 * Situacoes funcionais de acordo com o grupo
 *
 * 1-Estadual
 * 2-Municipal
 * 3-Inss
 * 4-Siape
 * 7-ForcasArmadas
 * 8-Tribunais
 */
export const ConveniosSituacaoFuncional = [
  {
    value: 1,
    options: SituacoesEstadual,
  },
  {
    value: 2,
    options: SituacoesMunicipal,
  },
  {
    value: 3,
    options: SituacoesInss,
  },
  {
    value: 4,
    options: SituacoesFederalSiape,
  },
  {
    value: 7,
    options: SituacoesForcasArmadas,
  },
  {
    value: 8,
    options: SituacoesTribunais,
  },
]

/**
 * Situacoes funcionais que nao estao liberadas
 *
 * 1-Estadual
 * 2-Municipal
 * 3-Inss
 * 4-Siape
 * 7-ForcasArmadas
 * 8-Tribunais
 */
export const SituacaoFuncionalNaoLiberado = {
  1: [
    'Pensionistas Curatelados/Tutelados',
    'Temporários / Contratados',
    'Convocados',
    'Estagiário / Aprendiz',
  ],
  2: ['Temporários / Contratados', 'Bolsista', 'Pensionistas Curatelados/Tutelados', 'Estagiários'],
  3: ['Pensionistas Curatelados/Tutelados'],
  4: ['Pensionistas Curatelados/Tutelados', 'Comissionados', 'Temporários / Contratados'],
  7: [
    'Pensionistas Curatelados/Tutelados',
    'Comissionados',
    'Temporários / Contratados',
    'Estagiários',
  ],
  8: ['CLT / Celetistas', 'Ministros politicamente expostos'],
}

export const SalarioSimulacao = 937

/**
 * Situacoes funcionais que nao estao liberadas
 *
 * 1-Estadual
 * 2-Municipal
 * 3-Inss
 * 4-Siape
 * 7-ForcasArmadas
 * 8-Tribunais
 */
export const SituacoesFuncionaisSalesforce = {
  ESTADUAL: [
    {
      value: 'Ativos / Efetivos / Concursados / Estatutários',
      text: 'Servidor efetivo',
    },
    { value: 'Aposentados / Inativos', text: 'Aposentado(a)' },
    { value: 'Bolsistas', text: 'Servidor efetivo' },
    { value: 'Comissionados', text: 'Servidor efetivo' },
    { value: 'CLT / Celetistas', text: 'Servidor efetivo' },
    { value: 'Pensionistas', text: 'Pensionista' },
  ],
  MUNICIPAL: [
    { value: 'Ativos / Efetivos / Concursado', text: 'Servidor efetivo' },
    { value: 'Aposentados', text: 'Aposentado(a)' },
    { value: 'Pensionistas', text: 'Pensionista' },
    { value: 'Celetistas', text: 'Servidor efetivo' },
    { value: 'Comissionados', text: 'Servidor efetivo' },
  ],
  INSS: [
    { value: 'Aposentados', text: 'Aposentado(a)' },
    { value: 'Pensionistas', text: 'Pensionista' },
  ],
  FEDERAL_SIAPE: [
    { value: 'Ativos / Efetivos', text: 'Civil ativo efetivo' },
    { value: 'Aposentados / Inativos', text: 'Aposentado(a)' },
    { value: 'Pensionistas Vitalícios', text: 'Pensionista vitalício' },
    {
      value: 'Pensionistas Curatelados/Tutelados',
      text: 'Pensionista curatelado(a)/tutelado(a)',
    },
    { value: 'Comissionados', text: 'Comissionado(a)' },
    { value: 'Temporários / Contratados', text: 'Temporário(a)' },
  ],
  FORCAS_ARMADAS: [
    { value: 'Ativos / Efetivos', text: 'Ativo(a)' },
    { value: 'Inativos', text: 'Inativo(a)' },
    { value: 'Veteranos', text: 'Estabilizado(a)' },
    { value: 'Pensionistas', text: 'Estabilizado(a)' },
  ],
  TRIBUNAIS: [
    { value: 'Ativos / Efetivos', text: 'Ativo(a)' },
    { value: 'Magistrados', text: 'Ativo(a)' },
    { value: 'Aposentados / Inativos', text: 'Aposentado(a)' },
    { value: 'Pensionistas', text: 'Pensionista' },
    { value: 'Comissionados', text: 'Ativo(a)' },
    { value: 'Temporários / Contratados', text: 'Ativo(a)' },
  ],
}
