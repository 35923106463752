import React, { useCallback, useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { Page } from '@templates'
import { Button } from '@interco/inter-ui/components/Button'
import { ApplicationState } from '@store/types'
import { Input, InputHelperButton, Paragraph } from '@atoms'
import { Colors } from '@utils/colors'
import {
  ConveniosCodes,
  FontesPagamento,
  PatentesExercito,
  SituacaoFuncionalNaoLiberado,
  StepPortabilidade,
} from '@utils/enums'
import { useTrackingPortabilidade } from '@hooks'
import routes from '@routes/routes'
import { salvarLeadRequest, trackingRequest } from '@store/portabilidade/simulacao/actions'
import { Dialog } from '@molecules'
import { SituacaoFuncionalComponentsTypes } from '@store/portabilidade/situacaoFuncional/types'
import SituacaoFuncionalDropdown from '@organisms/InputsDropdown/SituacaoFuncionalDropdown'
import { findConveniosSituacaoFuncional } from '@utils/functions'
import {
  transformMotivoToOption,
  transformPatenteToOption,
  transformSituacaoToOption,
  transformUpagToOption,
} from '@utils/transformers'
import {
  setAnoIngresso,
  setMatricula,
  setMotivo,
  setPatente,
  setSituacaoFuncional,
  setUpag,
} from '@store/portabilidade/situacaoFuncional/actions'
import { CustomBottomSheet } from '@organisms/InputsDropdown/styles'
import UpagDropdown from '@organisms/InputsDropdown/UpagDropdown'
import MotivoDropdown from '@organisms/InputsDropdown/MotivoDropdown'
import MatriculaInput from '@organisms/InputsDropdown/MatriculaInput'
import { numberMask, precCPMask } from '@utils/masks'
import { dialogVariants, VariantDialog } from '@utils/bottomSheetVariants'
import { setSalario, upagsRequest } from '@store/portabilidade/dadosProfissionais/actions'
import SalarioInput from '@organisms/InputsDropdown/SalarioInput'

import { ContentImage } from './styles'

const SituacaoFuncional = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { fonteSelecionada, convenioSelecionado, salario } = useSelector(
    (state: ApplicationState) => state.portabilidade.dadosProfissionais,
  )

  const { loading } = useSelector((state: ApplicationState) => state.portabilidade.simulacao)

  const {
    upagSelecionada,
    motivo,
    patente,
    anoIngresso,
    situacaoFuncional,
    matricula,
    matriculaValida = true,
  } = useSelector((state: ApplicationState) => state.portabilidade.situacaoFuncional)

  const { upags = [], upagsLoading } = useSelector((state: ApplicationState) => state.globalApi)

  const [open, setOpen] = useState(false)
  const [variant, setVariant] = useState<VariantDialog>()
  const [disabled, setDisabled] = useState(false)

  useTrackingPortabilidade(StepPortabilidade.SITUACAO_FUNCIONAL)

  useEffect(() => {
    if (!upags || upags.length === 0) {
      dispatch(upagsRequest())
    }
  }, [dispatch, upags])

  const shouldDisable = useCallback(() => {
    if (
      fonteSelecionada === FontesPagamento.FEDERAL_SIAPE &&
      (!matricula || !matriculaValida || !salario || !situacaoFuncional || salario < 500)
    ) {
      return true
    }
    if (
      fonteSelecionada === FontesPagamento.FORCAS_ARMADAS &&
      convenioSelecionado === ConveniosCodes.EXERCITO_BR &&
      (!patente || !anoIngresso || !matricula || !matriculaValida || !situacaoFuncional)
    ) {
      return true
    }
    if (
      fonteSelecionada === FontesPagamento.INSS &&
      (!situacaoFuncional ||
        ((situacaoFuncional === 'Aposentados' || situacaoFuncional === 'Pensionistas') && !motivo))
    ) {
      return true
    }
    return false
  }, [
    anoIngresso,
    convenioSelecionado,
    fonteSelecionada,
    matricula,
    matriculaValida,
    motivo,
    patente,
    salario,
    situacaoFuncional,
  ])

  useEffect(() => {
    setDisabled(shouldDisable())
  }, [shouldDisable])

  const showDropdown = (component: SituacaoFuncionalComponentsTypes) => {
    if (
      (component === 'upag' || component === 'matricula' || component === 'salario') &&
      fonteSelecionada === FontesPagamento.FEDERAL_SIAPE
    ) {
      return true
    }
    if (
      (component === 'patente' || component === 'prec-cp' || component === 'ano-ingresso') &&
      fonteSelecionada === FontesPagamento.FORCAS_ARMADAS &&
      convenioSelecionado === ConveniosCodes.EXERCITO_BR
    ) {
      return true
    }
    if (
      component === 'motivo' &&
      fonteSelecionada === FontesPagamento.INSS &&
      (situacaoFuncional === 'Aposentados' || situacaoFuncional === 'Pensionistas')
    ) {
      return true
    }
    return false
  }

  type SituacaoNumeroFonte = 1 | 2 | 3 | 4 | 7 | 8

  const verificarSituacaoNaoAtendida = () => {
    let isSituacaoNaoAtendida = false
    SituacaoFuncionalNaoLiberado[Number(fonteSelecionada) as SituacaoNumeroFonte].map((opcao) => {
      if (situacaoFuncional === opcao) {
        isSituacaoNaoAtendida = true
        return ''
      }
      return ''
    })
    if (isSituacaoNaoAtendida) {
      navigate(routes.NAO_LIBERADO)
    } else {
      dispatch(
        salvarLeadRequest({
          callback: () => {
            navigate(routes.DADOS_RECEBIDOS)
          },
        }),
      )
    }
  }

  return (
    <Page
      id="page-situacao-funcional"
      stickyFooter={
        <Button
          disabled={disabled || loading}
          isLoading={loading}
          onClick={() => {
            dispatch(trackingRequest(StepPortabilidade.CONTINUAR_SITUACAO_FUNCIONAL))
            verificarSituacaoNaoAtendida()
          }}
        >
          Continuar
        </Button>
      }
    >
      <Paragraph variant="inter" color={Colors.GRAY400} margin="13px 0 24px">
        Precisamos de mais alguns dados para continuar:
      </Paragraph>

      <SituacaoFuncionalDropdown
        id="dropdown-situacao-funcional"
        situacoesFuncionais={findConveniosSituacaoFuncional(Number(fonteSelecionada))}
        situacaoFuncional={transformSituacaoToOption(
          findConveniosSituacaoFuncional(Number(fonteSelecionada)),
          String(situacaoFuncional),
        )}
        onChange={(option) => {
          dispatch(setSituacaoFuncional(String(option.value)))
          if (motivo) {
            dispatch(setMotivo(''))
          }
        }}
        setOpen={() => setOpen(true)}
        setVariant={setVariant}
      />

      <>
        {showDropdown('upag') && (
          <UpagDropdown
            id="dropdown-upag-siape"
            upagSelecionado={transformUpagToOption(upags, String(upagSelecionada))}
            upags={upags}
            onChange={(option) => dispatch(setUpag(String(option.value)))}
            loading={Boolean(upagsLoading)}
            disabled={upagsLoading}
            setOpen={() => setOpen(true)}
            setVariant={setVariant}
          />
        )}
      </>
      <>
        {showDropdown('motivo') && (
          <MotivoDropdown
            id="ui-dropdown-motivo-inss"
            onChange={(option) => dispatch(setMotivo(String(option.value)))}
            motivo={transformMotivoToOption(String(motivo))}
            situacaoFuncional={String(situacaoFuncional)}
            loading={false}
          />
        )}
      </>
      <>
        {showDropdown('prec-cp') && (
          <MatriculaInput
            id="input-numero-prec-cp"
            label="Qual é seu número de PREC-CP?"
            placeholder="Digite aqui seu PREC-CP"
            matricula={precCPMask(matricula)}
            matriculaError={!matriculaValida}
            onChange={(e) => {
              dispatch(setMatricula(e))
            }}
            invalidText="Matrícula não encontrada"
            maxLength={10}
            infoButton={<InputHelperButton onClick={() => setOpen(true)} />}
          />
        )}
      </>
      <>
        {showDropdown('patente') && (
          <CustomBottomSheet
            id="dropdown-patente-exercito"
            value={transformPatenteToOption(PatentesExercito, String(patente))}
            label="Qual é a sua patente?"
            name="dropdown"
            onChange={(option) => dispatch(setPatente(String(option.value)))}
            options={PatentesExercito}
            placeholder="Selecione sua patente"
            isUsingFilter
            disabled={!situacaoFuncional}
          />
        )}
      </>
      <>
        {showDropdown('ano-ingresso') && (
          <Input
            id="input-ano-ingresso"
            label="Ano de ingresso"
            placeholder="Digite seu ano de ingresso"
            value={anoIngresso}
            onChange={(e) => dispatch(setAnoIngresso(Number((e.target as HTMLInputElement).value)))}
            maxLength={4}
          />
        )}
      </>

      <>
        {showDropdown('matricula') && (
          <MatriculaInput
            id="input-numero-matricula"
            matricula={matricula}
            matriculaError={!matriculaValida}
            onChange={(e) => dispatch(setMatricula(numberMask(e)))}
            maxLength={20}
            infoButton={
              <InputHelperButton
                onClick={() => {
                  setOpen(true)
                  setVariant(dialogVariants.matricula)
                }}
              />
            }
          />
        )}
      </>

      <>
        {showDropdown('salario') && (
          <SalarioInput
            id="input-salario"
            salario={salario}
            label="Qual seu salário bruto?"
            onChange={(e) => dispatch(setSalario(e))}
          />
        )}
      </>
      <></>

      {open ? (
        <Dialog
          onClose={() => setOpen(false)}
          headerTitle={variant?.title || ''}
          description={variant?.text}
          buttonText="Entendi"
        >
          {variant === dialogVariants.siglaUpag || dialogVariants.numeroPRECCP ? (
            <ContentImage>{variant?.image}</ContentImage>
          ) : (
            <></>
          )}
        </Dialog>
      ) : (
        <></>
      )}
    </Page>
  )
}

export default SituacaoFuncional
