import getBancos from './getBancos'
import getFontesPagamentos from './getFontesPagamentos'
import getConvenios from './getConvenios'
import getCidades from './getCidades'
import getEstados from './getEstados'
import getOrgaos from './getOrgaos'
import getUpags from './getUpags'
import postSalvarLeadPortabilidade from './postSalvarLeadPortabilidade'
import getValidaCliente from './getValidaCliente'
import postValidaPortabilidade from './postValidaPortabilidade'
import postValidaMatricula from './postValidaMatricula'
import getValidaToken from './getValidaToken'
import postEnviaToken from './postEnviaToken'
import getDadosBeneficiarioInss from './getDadosBeneficiarioInss'

export default {
  getBancos,
  getFontesPagamentos,
  getConvenios,
  getCidades,
  getEstados,
  getOrgaos,
  getUpags,
  postSalvarLeadPortabilidade,
  getValidaCliente,
  postValidaPortabilidade,
  postValidaMatricula,
  getValidaToken,
  postEnviaToken,
  getDadosBeneficiarioInss,
}
