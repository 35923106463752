import { takeLatest, SagaGenerator } from 'typed-redux-saga'

import { GlobalApiTypes } from '@store/globalApi/types'
import {
  getBancos,
  getCidades,
  getConvenios,
  getEstados,
  getFontesPagamento,
  getOrgaos,
  getUpags,
} from '@store/globalApi/sagas'

import { SimulacaoTypes } from './simulacao/types'
import { postSalvarLead, postTrackPortabilidade } from './simulacao/sagas'
import { ValidaTypes } from './validacao/types'
import { postValida } from './validacao/sagas'
import { SituacaoFuncionalTypes } from './situacaoFuncional/types'
import { getDadosBeneficiarioInssSaga, postValidarMatriculaSaga } from './dadosProfissionais/sagas'
import { DadosProfissionaisTypes } from './dadosProfissionais/types'

export default function* watch(): SagaGenerator<void> {
  yield* takeLatest(GlobalApiTypes.BANCOS_REQUEST, getBancos)
  yield* takeLatest(GlobalApiTypes.FONTES_REQUEST, getFontesPagamento)
  yield* takeLatest(GlobalApiTypes.CONVENIOS_REQUEST, getConvenios)
  yield* takeLatest(GlobalApiTypes.ESTADOS_REQUEST, getEstados)
  yield* takeLatest(GlobalApiTypes.CIDADES_REQUEST, getCidades)
  yield* takeLatest(GlobalApiTypes.ORGAOS_REQUEST, getOrgaos)
  yield* takeLatest(DadosProfissionaisTypes.UPAGS_REQUESTS, getUpags)
  yield* takeLatest(ValidaTypes.VALIDA_REQUEST, postValida)
  yield* takeLatest(DadosProfissionaisTypes.VALIDAR_MATRICULA, postValidarMatriculaSaga)
  yield* takeLatest(SituacaoFuncionalTypes.VALIDAR_MATRICULA, postValidarMatriculaSaga)
  yield* takeLatest(SimulacaoTypes.SALVAR_LEAD_REQUEST, postSalvarLead)
  yield* takeLatest(SimulacaoTypes.TRACKING_REQUEST, postTrackPortabilidade)
  yield* takeLatest(
    DadosProfissionaisTypes.DADOS_BENEFICIARIO_INSS_REQUEST,
    getDadosBeneficiarioInssSaga,
  )
}
