import { Reducer } from 'redux'

import { DadosProfissionaisState, DadosProfissionaisTypes } from './types'

const INITIAL_STATE: DadosProfissionaisState = {
  fonteSelecionada: undefined,
  convenioSelecionado: '',
  estadoSelecionado: '',
  cidadeSelecionada: '',
  orgaoSelecionado: '',
  salario: 0,
  matriculaValida: true,
  matricula: '',
  checkAutorizacaoInss: false,
  dadosBeneciario: {
    isConsultaRealizada: false,
    isBeneficioValido: false,
    beneficiado: {
      estado: '',
      situacaoFuncional: '',
      margem: '',
      dataNascimento: '',
    },
    infoInss: {
      banco: '',
      agencia: '',
      conta: '',
      digitoConta: '',
      tipoCredito: '',
      margem: 0,
      nome: '',
    },
    pendencia: '',
  },

  dadosInssLoading: false,
  dadosInssError: false,
}

const reducer: Reducer<DadosProfissionaisState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DadosProfissionaisTypes.SET_FONTE_SELECIONADA:
      return { ...state, fonteSelecionada: action.payload }
    case DadosProfissionaisTypes.SET_CONVENIO_SELECIONADO:
      return { ...state, convenioSelecionado: action.payload }
    case DadosProfissionaisTypes.SET_ESTADO_SELECIONADO:
      return { ...state, estadoSelecionado: action.payload }
    case DadosProfissionaisTypes.SET_CIDADE_SELECIONADA:
      return { ...state, cidadeSelecionada: action.payload }
    case DadosProfissionaisTypes.SET_ORGAO_SELECIONADO:
      return { ...state, orgaoSelecionado: action.payload }
    case DadosProfissionaisTypes.SET_SALARIO:
      return { ...state, salario: action.payload }
    case DadosProfissionaisTypes.SET_MATRICULA:
      return { ...state, matricula: action.payload }
    case DadosProfissionaisTypes.SET_MATRICULA_VALIDA:
      return { ...state, matriculaValida: action.payload, loading: false }
    case DadosProfissionaisTypes.VALIDAR_MATRICULA:
      return { ...state, loading: true, error: false }
    case DadosProfissionaisTypes.DADOS_BENEFICIARIO_INSS_REQUEST:
      return { ...state, dadosInssError: false, dadosInssLoading: true }
    case DadosProfissionaisTypes.DADOS_BENEFICIARIO_INSS_SUCCESS:
      return {
        ...state,
        dadosBeneciario: action.payload,
        dadosInssError: false,
        dadosInssLoading: false,
      }
    case DadosProfissionaisTypes.DADOS_BENEFICIARIO_INSS_ERROR:
      return { ...state, dadosInssError: true, dadosInssLoading: false }
    case DadosProfissionaisTypes.SET_CHECK_AUTORIZACAO_INSS:
      return { ...state, checkAutorizacaoInss: action.payload }
    case DadosProfissionaisTypes.RESET:
      return { ...INITIAL_STATE }
    default:
      return state
  }
}

export default reducer
