import { BaseServices, HttpMethod } from '@services/base'

const getValidaToken = (data: ValidaCodigoApiRequest, captcha: string) => {
  const pathname = `${process.env.REACT_APP_ENVIA_CODIGO}?codigo=${data.codigo}&id=${data.id}&origem=${data.origem}`
  const method = HttpMethod.GET
  const host = process.env.REACT_APP_API_HOST
  const headers = { captcha }

  const response = BaseServices.request({
    headers,
    pathname,
    method,
    host,
  })
  return response
}

export interface ValidaCodigoApiRequest {
  codigo: string
  id: string
  origem: string
}

export default getValidaToken
