import React from 'react'

import Paragraph from '@atoms/Paragraph'
import { Alert } from '@interco/inter-ui/components/Alert'
import { Link } from '@interco/inter-ui/components/Link'
import SecurityIcon from '@interco/icons/orangeds/SM/security'
import { Colors } from '@utils/colors'

export const AlertLGPD = () => (
  <Alert
    title="Seus dados estão protegidos!"
    type="warning"
    customIcon={<SecurityIcon height={20} width={20} color={Colors.GRAY500} />}
  >
    <Paragraph fontSize="12px" lineHeight="15px" color={Colors.GRAY500} margin="4px 0 0">
      Para oferecer este serviço, trataremos os seus dados de cadastro e outros fornecidos por você
      com privacidade e segurança. Também poderemos entrar em contato. Aproveite para conferir a
      nossa{' '}
      <Link
        href="https://www.bancointer.com.br/politica-de-privacidade/"
        variant="caption-1"
        color="primary"
        bold
      >
        Política de Privacidade.
      </Link>
    </Paragraph>
  </Alert>
)
