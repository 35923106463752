/**
 * Actions Types
 */

export enum NavigationTypes {
  SET_IS_APP = '@navigation/SET_IS_APP',
  SET_ORIGEM = '@navigation/SET_ORIGEM',
  SET_TITLE = '@navigation/SET_TITLE',
  SET_HIDE_HEADER = '@navigation/SET_HIDE_HEADER',
  SET_HIDE_FOOTER = '@navigation/SET_HIDE_FOOTER',
  SET_ORIGEM_GO_BACK = '@navigation/SET_ORIGEM_GO_BACK',
  RESET = '@navigation/RESET',
}

/**
 * State Type
 */

export interface NavigationState {
  readonly isApp: boolean
  readonly origem: string
  readonly title: string
  readonly hideHeader: boolean
  readonly hideFooter: boolean
  readonly origemGoBack?: string
}
