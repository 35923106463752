import { useEffect } from 'react'

import { useDispatch } from 'react-redux'

import { StepPortabilidade } from '@utils/enums'
import { trackingRequest } from '@store/portabilidade/simulacao/actions'

const TrackingPortabilidade = (step: StepPortabilidade) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(trackingRequest(step))
  }, [dispatch, step])
}

export default TrackingPortabilidade
