/**
 * Actions Types
 */

export enum SimulacaoTypes {
  SIMULACAO_REQUEST = '@simulacao/SIMULACAO_REQUEST',
  SIMULACAO_SUCCESS = '@simulacao/SIMULACAO_SUCCESS',
  SIMULACAO_ERROR = '@simulacao/SIMULACAO_ERROR',
  SALVAR_LEAD_REQUEST = '@simulacao/SALVAR_LEAD_REQUEST',
  SALVAR_LEAD_SUCCESS = '@simulacao/SALVAR_LEAD_SUCCESS',
  SALVAR_LEAD_ERROR = '@simulacao/SALVAR_LEAD_ERROR',
  TRACKING_REQUEST = '@simulacao/TRACKING_REQUEST',
  CLEAR_SIMULACAO = '@simulacao/CLEAR_SIMULACAO',

  RESET = 'RESET',
}

/**
 * State Type
 */

export interface SimulacaoState {
  readonly valorParcela: number
  readonly novoValorParcela: number
  readonly valorEconomia: number
  readonly validar: boolean
  readonly taxaNominalMensal: number
  readonly novoTaxaNominalMensal: number
  readonly valorSolicitado: number
  readonly valorFinanciado: number
  readonly leadEnviado: boolean
  readonly loading?: boolean
  readonly error?: boolean
}

/**
 * Actions Payload Types
 */
export interface CallbackSimulacaoType {
  valida: boolean
  naoLiberado: boolean
}
export type SimulacaoRequestPayload = {
  callback: (params: CallbackSimulacaoType) => void
}
export type SalvarLeadRequestPayload = {
  callback: () => void
}

export interface InfoParcelas {
  qtdeParcelas: number
  valorSolicitado: number
  valorSolicitadoPercentual: number
  valorParcela: number
  valorLiberado: number
  valorIOF: number
  percentualIOF: number
  valorFinanciado: number
  cETAm: number
  cETAe: number
  taxaNominalAm: number
  taxaNominalAa: number
  jurosContratados: number
  jurosContratadosPercentual: number
}

export interface SimulacaoResponse {
  simulacoes: InfoParcelas[]
}
