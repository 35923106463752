import { action, Action } from 'typesafe-actions'

import { StepPortabilidade } from '@utils/enums'

import {
  SalvarLeadRequestPayload,
  SimulacaoRequestPayload,
  SimulacaoResponse,
  SimulacaoTypes,
} from './types'

export const simulacaoRequest = (payload: SimulacaoRequestPayload): Action =>
  action(SimulacaoTypes.SIMULACAO_REQUEST, payload)

export const simulacaoSucces = (payload: SimulacaoResponse): Action =>
  action(SimulacaoTypes.SIMULACAO_SUCCESS, payload)

export const simulacaoError = (): Action => action(SimulacaoTypes.SIMULACAO_ERROR)

export const clearSimulacao = (): Action => action(SimulacaoTypes.CLEAR_SIMULACAO)

export const salvarLeadRequest = (payload: SalvarLeadRequestPayload): Action =>
  action(SimulacaoTypes.SALVAR_LEAD_REQUEST, payload)

export const salvarLeadSuccess = (): Action => action(SimulacaoTypes.SALVAR_LEAD_SUCCESS)

export const salvarLeadError = (): Action => action(SimulacaoTypes.SALVAR_LEAD_ERROR)

export const trackingRequest = (payload: StepPortabilidade): Action =>
  action(SimulacaoTypes.TRACKING_REQUEST, payload)
