import { BaseServices, HttpMethod } from '@services/base'
import { FontePagamentoApi } from '@store/globalApi/types'

export default () => {
  const pathname = `${process.env.REACT_APP_FONTE_PAGAMENTOS}?tipo=PORTABILIDADE` // process.env.REACT_APP_FONTE_PAGAMENTOS
  const method = HttpMethod.GET
  const host = process.env.REACT_APP_API_HOST

  const response = BaseServices.request<FontePagamentoApi>({
    pathname,
    method,
    host,
  })
  return response
}
