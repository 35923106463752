import React from 'react'

import { Routes, Route, BrowserRouter } from 'react-router-dom'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import HeaderAndFooter from '@templates/HeaderAndFooter'
import {
  Contato,
  ConvenioForcasArmadas,
  DadosPessoais,
  DadosProfissionais,
  DadosRecebidos,
  InssSimplificado,
  NaoLiberado,
  Onboarding,
  SelecaoGrupo,
  SituacaoFuncional,
  Token,
  AutorizacaoConsultaInss,
  RootPage,
} from '@pages'
import { BridgeService } from '@services/bridge'
import { GoBackConfig } from '@utils/goBackConfig'

import pageRoutes from './routes'

const RoutesPortabilidade: React.FC = () => (
  <>
    {BridgeService.isBrowser() ? (
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''}
        useEnterprise
      >
        <BrowserRouter>
          <GoBackConfig />
          <HeaderAndFooter>
            <Routes>
              <Route path={pageRoutes.ROOT} element={<RootPage />} />
              <Route path={pageRoutes.ONBOARDING} element={<Onboarding />} />
              <Route path={pageRoutes.DADOS_PESSOAIS} element={<DadosPessoais />} />
              <Route path={pageRoutes.CONTATO} element={<Contato />} />
              <Route path={pageRoutes.TOKEN} element={<Token />} />
              <Route path={pageRoutes.SELECAO_GRUPO} element={<SelecaoGrupo />} />
              <Route path={pageRoutes.DADOS_RECEBIDOS} element={<DadosRecebidos />} />
              <Route path={pageRoutes.NAO_LIBERADO} element={<NaoLiberado />} />
              <Route path={pageRoutes.DADOS_PROFISSIONAIS} element={<DadosProfissionais />} />
              <Route
                path={pageRoutes.CONVENIO_FORCAS_ARMADAS}
                element={<ConvenioForcasArmadas />}
              />
              <Route path={pageRoutes.INSS_SIMPLIFICADO} element={<InssSimplificado />} />
              <Route path={pageRoutes.SITUACAO_FUNCIONAL} element={<SituacaoFuncional />} />
              <Route
                path={pageRoutes.AUTORIZACAO_CONSULTA_INSS}
                element={<AutorizacaoConsultaInss />}
              />
            </Routes>
          </HeaderAndFooter>
        </BrowserRouter>
      </GoogleReCaptchaProvider>
    ) : (
      <BrowserRouter>
        <GoBackConfig />
        <HeaderAndFooter>
          <Routes>
            <Route path={pageRoutes.ROOT} element={<RootPage />} />
            <Route path={pageRoutes.ONBOARDING} element={<Onboarding />} />
            <Route path={pageRoutes.DADOS_PESSOAIS} element={<DadosPessoais />} />
            <Route path={pageRoutes.CONTATO} element={<Contato />} />
            <Route path={pageRoutes.TOKEN} element={<Token />} />
            <Route path={pageRoutes.SELECAO_GRUPO} element={<SelecaoGrupo />} />
            <Route path={pageRoutes.DADOS_RECEBIDOS} element={<DadosRecebidos />} />
            <Route path={pageRoutes.NAO_LIBERADO} element={<NaoLiberado />} />
            <Route path={pageRoutes.DADOS_PROFISSIONAIS} element={<DadosProfissionais />} />
            <Route path={pageRoutes.CONVENIO_FORCAS_ARMADAS} element={<ConvenioForcasArmadas />} />
            <Route path={pageRoutes.INSS_SIMPLIFICADO} element={<InssSimplificado />} />
            <Route path={pageRoutes.SITUACAO_FUNCIONAL} element={<SituacaoFuncional />} />
            <Route
              path={pageRoutes.AUTORIZACAO_CONSULTA_INSS}
              element={<AutorizacaoConsultaInss />}
            />
          </Routes>
        </HeaderAndFooter>
      </BrowserRouter>
    )}
  </>
)

export default RoutesPortabilidade
