/**
 * Actions Types
 */

export enum ContratoAtualTypes {
  BANCOS_REQUEST = '@contratoAtual/BANCOS_REQUEST',
  SET_BANCO_SELECIONADO = '@contratoAtual/SET_BANCO_SELECIONADO',
  SET_NUMERO_CONTRATO = '@contratoAtual/SET_NUMERO_CONTRATO',

  RESET = 'RESET',
}

/**
 * State Type
 */

export interface ContratoAtualState {
  readonly bancoSelecionado?: string | number
  readonly valorParcela: string
  readonly loading?: boolean
  readonly error?: boolean
}
